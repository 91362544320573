h1, h2, h3, h4, nav, summary, button#start {
	font-weight: bold;
}

@media (min-width: 700px) {
	#about[open] {
		height: 429px;
	}
}

@media (min-width: 500px) {
	#settings[open] {
		height: 376px;
	}
}